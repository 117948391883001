import { fields } from "@monorepo/utils/src/variables/projectsData/eadStoragePeriodReportView/fields";

export const currentFilters = (): Record<string, unknown> => ({
  [fields.OIK_CODE]: [],
  [fields.ARCHIVE_NAME]: {},
  [fields.FUND_NUMBER]: {},
  [fields.DATE_OF_STORAGE_TO]: "",
  [fields.DATE_OF_STORAGE_FROM]: "",
  [fields.END_OF_STORAGE_YEAR]: "",
});
