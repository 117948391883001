import { fields } from "./fields";

export const filtersElements = [
  {
    value: fields.END_OF_STORAGE_YEAR,
    title: "Год окончания срока хранения",
  },
  {
    value: fields.DATE_OF_STORAGE,
    title: "Дата хранения ЭАД",
  },
  {
    value: fields.OIK_CODE,
    title: "ОИК",
  },
  {
    value: fields.ARCHIVE_NAME,
    title: "Название архива",
  },
  {
    value: fields.FUND_NUMBER,
    title: "№ фонда",
  },
];
