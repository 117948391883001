export interface IEadStoragePeriodReport {
  ARCHIVE_NAME: string;
  FUND_NUMBER: string;
  OIK_NAME: string;
  OIK_CODE: string;
  NUMBER: string;
  OIK_CREATION_DATE: string;
  OIK_REGISTRATION_DATE: string;
  TK_DELIVERY_DATE: string;
  EAD_STATUS: string;
  EAD_TITLE: string;
  EAD_STORAGE_EXPIRATION_DATE: string;
  EAD_STORAGE_PERIOD: string;
  EAD_ID: string;
  END_OF_STORAGE_YEAR: string;
  DATE_OF_STORAGE: string;
  DATE_OF_STORAGE_TO: string;
  DATE_OF_STORAGE_FROM: string;
}

export const fields: IEadStoragePeriodReport = {
  ARCHIVE_NAME: "archiveName",
  FUND_NUMBER: "fundNumber",
  OIK_NAME: "oikName",
  OIK_CODE: "oikCode",
  NUMBER: "index",
  OIK_CREATION_DATE: "createDate",
  OIK_REGISTRATION_DATE: "registrationDate",
  TK_DELIVERY_DATE: "deliveryDate",
  EAD_STATUS: "status",
  EAD_TITLE: "title",
  EAD_STORAGE_EXPIRATION_DATE: "expirationDate",
  EAD_STORAGE_PERIOD: "storagePeriod",
  EAD_ID: "id",
  END_OF_STORAGE_YEAR: "year",
  DATE_OF_STORAGE_TO: "eadExpirationDateTo",
  DATE_OF_STORAGE_FROM: "eadExpirationDateFrom",
  DATE_OF_STORAGE: "DATE_OF_STORAGE",
};
