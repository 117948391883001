































































import Table from "@monorepo/uikit/src/components/common/Table.vue";
import TableHeader from "@monorepo/uikit/src/components/tableViews/TableHeader.vue";
import Filters from "@monorepo/reports/src/views/EndStoragePeriodEad/components/Filters.vue";
import Breadcrumbs from "@monorepo/uikit/src/components/common/Breadcrumbs.vue";
import InfiniteScroll from "@monorepo/uikit/src/components/tableViews/InfiniteScroll.vue";
import ScrollPanel from "@monorepo/uikit/src/components/tableViews/ScrollPanel.vue";
import { tableHeaders } from "@monorepo/utils/src/variables/projectsData/eadStoragePeriodReportView/tableHeaders";
import { viewTitle, viewUniqKey } from "@monorepo/utils/src/variables/projectsData/eadStoragePeriodReportView/viewTitle";
import { defineComponent, ref } from "@vue/composition-api";
import { module as EadStoragePeriodReport } from "@monorepo/reports/src/store/modules/eadStoragePeriodReport";
import useInitTableStoreModule from "@monorepo/utils/src/store/composables/useInitTableStoreModule";
import { mapActions, mapGetters, Module } from "vuex";
import { breadcrumbs } from "@monorepo/reports/src/views/EndStoragePeriodEad/constants/breadcrumbs";
import TableSettings from "@monorepo/uikit/src/components/tableViews/TableSettings.vue";
import ToggleTableCellsContainer from "./components/ToggleTableCellsContainer.vue";
import ExportButton from "@monorepo/uikit/src/components/tableViews/ExportButton.vue";
import useSetStartFilters from "@monorepo/utils/src/composables/useSetStartFilters";
import useInitEducationPanel from "@monorepo/utils/src/composables/useInitEducationPanel";
import useSetStartCells from "@monorepo/utils/src/composables/useSetStartCells";
import SortPanelContainer from "@monorepo/uikit/src/components/common/SortPanelContainer.vue";
import useGetTableLibs from "./composables/useGetTableLibs";
import { convertApiItemToUi } from "@monorepo/reports/src/views/EndStoragePeriodEad/utils/convertApiItemToUi";
import useAsyncExport from "@monorepo/utils/src/composables/useAsyncExport";

const SECTION_NAME = "eadStoragePeriodReport";

export default defineComponent({
  name: "eadStoragePeriodReportView",
  components: {
    TableSettings,
    ExportButton,
    ToggleTableCellsContainer,
    InfiniteScroll,
    SortPanelContainer,
    Table,
    Filters,
    ScrollPanel,
    TableHeader,
    Breadcrumbs,
  },
  data() {
    return {
      breadcrumbs,
      viewTitle,
      viewUniqKey,
      tableHeaders,
      convertApiItemToUi,
    };
  },
  computed: {
    ...mapGetters(SECTION_NAME, ["cells", "isOpenFilters", "openedId", "infiniteId", "data", "totalLength", "isTableLoading"]),
    headers(): typeof tableHeaders {
      return tableHeaders;
    },
  },
  methods: {
    ...mapActions(SECTION_NAME, ["getEventList", "getExportData", "addOpenedId", "setIsOpenFilters"]),
    toggleModal(value: boolean) {
      if (!value) {
        this.$store.dispatch(`${SECTION_NAME}/addOpenedId`, null);
      }
    },
    setCells(value: { value: string }[]) {
      this.$store.dispatch(
        `${SECTION_NAME}/setCells`,
        value.map((item) => item.value)
      );
    },
  },
  setup(props, { root }) {
    const filtersLoaded = ref(false);
    const sectionName = ref(SECTION_NAME); // sectionName === store module name

    useInitTableStoreModule(root, SECTION_NAME, EadStoragePeriodReport as Module<unknown, unknown>);
    useSetStartCells(root, SECTION_NAME, root.$store.getters[`${SECTION_NAME}/section`], tableHeaders);
    useSetStartFilters(root, SECTION_NAME, ["id"]).then(() => {
      useInitEducationPanel(root);
      filtersLoaded.value = true;
    });

    useGetTableLibs(root);
    useAsyncExport(root, SECTION_NAME);

    return {
      filtersLoaded,
      sectionName,
    };
  },
});
