






























































































import moment from "moment";
import { isEmpty } from "lodash";
import { defineComponent, ref } from "@vue/composition-api";
import { currentFilters as setCurrentFilters } from "../utils/defaultCurrentFilters";
import mask from "@monorepo/utils/src/directives/mask";
import SelectFilter from "@monorepo/uikit/src/components/common/Select/SelectFilter.vue";
import { GeneratedIntervalTypes } from "@monorepo/utils/src/types/IScheduledReport";
import TextFilter from "@monorepo/uikit/src/components/tableViews/TextFilter.vue";
import DatePicker from "@monorepo/uikit/src/components/tableViews/DatePicker.vue";
import { mapActions, mapGetters } from "vuex";
import { viewUniqKey } from "@monorepo/utils/src/variables/projectsData/eadStoragePeriodReportView/viewTitle";
import { filtersElements } from "@monorepo/utils/src/variables/projectsData/eadStoragePeriodReportView/filtersElements";
import FindButton from "@monorepo/uikit/src/components/tableViews/FindButton.vue";
import ClearFilters from "@monorepo/uikit/src/components/tableViews/ClearFilters.vue";
import ToggleFilters from "@monorepo/uikit/src/components/tableViews/ToggleFilters.vue";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { fields } from "@monorepo/utils/src/variables/projectsData/eadStoragePeriodReportView/fields";
import { defaultFilterPlaceholder } from "@monorepo/utils/src/constants";
import Autocomplete from "@monorepo/uikit/src/components/tableViews/Autocomplete.vue";
import { convertToSelectItems } from "@monorepo/utils/src/utils/convertToSelectItems";
import useTemplateFilters from "@monorepo/utils/src/composables/useTemplateFilters";
import useFilter from "@monorepo/utils/src/composables/useFilter";
import useAutocomplete from "@monorepo/utils/src/composables/useAutocomplete";

const autocompleteFiltersKeys = [fields.FUND_NUMBER, fields.ARCHIVE_NAME];

export default defineComponent({
  components: {
    TextFilter,
    DatePicker,
    SelectFilter,
    FindButton,
    ClearFilters,
    ToggleFilters,
    Autocomplete,
  },
  props: {
    sectionName: {
      type: String,
      default: "",
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  directives: {
    mask,
  },
  data() {
    return {
      filtersElements,
      viewUniqKey,
      fields,
      yearMask: {
        mask: "9999",
      },
      GeneratedIntervalTypes,
      defaultFilterPlaceholder,
      autocompleteFiltersKeys,
    };
  },
  computed: {
    ...mapGetters("eadStoragePeriodReport", ["fieldFilters", "searchTemplates", "oiksLibrary", "isTableLoading", "isLoadingToggleFilters"]),
    oiks(): { title: string; value: string }[] {
      return convertToSelectItems(this.oiksLibrary, "name", "code");
    },
  },
  methods: {
    ...mapActions("eadStoragePeriodReport", ["addFilter", "clearEventList", "saveSearchTemplates"]),
    ...mapActions("eadStoragePeriodReport", ["refreshScroll", "getArchiveAutocompleteByCode", "getFundAutocompleteByCode"]),
    clearFilters() {
      this.currentFilters = setCurrentFilters();
      this.addFilter(this.currentFilters);
      this.clearEventList();
    },
    refreshReport() {
      const keys: string[] = [fields.FUND_NUMBER, fields.ARCHIVE_NAME, fields.OIK_CODE, fields.END_OF_STORAGE_YEAR];
      if (keys.some((key) => isEmpty(this.currentFilters[key]))) {
        showNotification("Заполните все фильтры для формирования отчета");
        return;
      }

      const year = this.currentFilters[fields.END_OF_STORAGE_YEAR] as string;
      const fromDate = this.currentFilters[fields.DATE_OF_STORAGE_FROM] as string;
      const toDate = this.currentFilters[fields.DATE_OF_STORAGE_TO] as string;
      if (
        fromDate &&
        toDate &&
        (!moment(fromDate, "YYYY-MM-DD").isSameOrBefore(moment(year, "YYYY"), "year") ||
          !moment(toDate, "YYYY-MM-DD").isSameOrAfter(moment(year, "YYYY"), "year"))
      ) {
        showNotification("Фильтр 'Год окончания срока хранения' должен входить в диапазон фильтра 'Дата хранения ЭАД'");
        return;
      }
      this.addFilter(this.currentFilters);
      this.refreshScroll();
    },
    setCurrentFilters,
  },
  setup(props, context) {
    const { root } = context;
    const currentFilters = ref((setCurrentFilters as () => Record<string, unknown>)());
    const notHiddenKeys = ref([] as string[]);
    const isShowToggle = ref(false);
    const autocompleteItemsResultLists = ref({} as { [key: string]: Record<string, unknown>[] });
    const isLoadingAutocomplete = ref(false);
    useTemplateFilters(root, props.sectionName, currentFilters);
    const { filter, hiddenRowsStyleObj, openHiddenFilterOnStart, toggleOpenFilter, clearFiltersCb, refresh, updateFiltersDivHeight } = useFilter(
      context,
      props as {
        value: boolean;
      },
      props.sectionName,
      {},
      currentFilters,
      setCurrentFilters,
      filtersElements as { value: string; title: string }[],
      notHiddenKeys,
      isShowToggle
    );
    const { getAutocompleteItems } = useAutocomplete(
      root,
      props.sectionName,
      currentFilters,
      autocompleteItemsResultLists,
      isLoadingAutocomplete,
      autocompleteFiltersKeys as string[]
    );

    return {
      filter,
      hiddenRowsStyleObj,
      openHiddenFilterOnStart,
      toggleOpenFilter,
      clearFiltersCb,
      refresh,
      updateFiltersDivHeight,
      currentFilters,
      notHiddenKeys,
      isShowToggle,
      getAutocompleteItems,
      autocompleteItemsResultLists,
      isLoadingAutocomplete,
    };
  },
});
