import { v4 as uuid } from "uuid";
import { fields } from "./fields";

export const tableHeaders = [
  { text: "№ по порядку", defaultWidth: 120, value: fields.NUMBER, isLink: false, isSorted: false, isHandle: true, id: uuid() },
  { text: "Дата создания документа в ОИК", defaultWidth: 200, value: fields.OIK_CREATION_DATE, isSorted: false, isHandle: true, id: uuid() },
  { text: "Дата регистрации документа в ОИК", defaultWidth: 200, value: fields.OIK_REGISTRATION_DATE, isSorted: false, isHandle: true, id: uuid() },
  {
    text: "Дата поступления документа в ЦХЭД",
    isLink: false,
    defaultWidth: 200,
    value: fields.TK_DELIVERY_DATE,
    isSorted: false,
    isHandle: true,
    id: uuid(),
  },
  { text: "Статус", defaultWidth: 200, value: fields.EAD_STATUS, isSorted: false, isHandle: true, id: uuid() },
  { text: "Заголовок документа", defaultWidth: 200, value: fields.EAD_TITLE, isSorted: false, isHandle: true, id: uuid() },
  { text: "Дата хранения ЭАД", defaultWidth: 200, value: fields.EAD_STORAGE_EXPIRATION_DATE, isSorted: false, isHandle: true, id: uuid() },
  { text: "Срок хранения ЭАД, лет", defaultWidth: 200, value: fields.EAD_STORAGE_PERIOD, isSorted: true, isHandle: true, id: uuid() },
  { text: "Идентификатор ЭАД", isLink: false, defaultWidth: 200, value: fields.EAD_ID, isSorted: false, isHandle: true, id: uuid() },
  { text: "Название ОИК", defaultWidth: 200, value: fields.OIK_NAME, isSorted: false, isHandle: true, id: uuid() },
  { text: "№ фонда", isLink: false, defaultWidth: 200, value: fields.FUND_NUMBER, isSorted: false, isHandle: true, id: uuid() },
  { text: "Название архива", isLink: false, defaultWidth: 200, value: fields.ARCHIVE_NAME, isSorted: false, isHandle: true, id: uuid() },
];
